import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginFacadeService } from '@modules/autenticacion/login/store/login.facade.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  helper: any;
  constructor(private loginFacadeService: LoginFacadeService) {
    this.helper = new JwtHelperService();
  }

  getTokenMapper = (token: any) => {
    if (!token) {
      return false;
    }
    if (this.helper.isTokenExpired(token)) {
      return false;
    }
    return true;
  };

  canActivate(): Observable<boolean> {
    return this.loginFacadeService.getToken().pipe(map(this.getTokenMapper));
  }
}
