import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Numbers } from '@constants/numbers';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Breadcrumb {
  name: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    //
  }

  generateBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.createBreadcrumbs(this.activatedRoute.root))
    );
  }

  createBreadcrumbs(
    activatedRoute: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = activatedRoute.children;

    if (children.length === Numbers._0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

      if (routeURL) {
        // Construir la URL estándar.
        url += `/${routeURL}`;

        // Si existe un `breadcrumbPath`, usarlo en lugar de la URL estándar.
        const customPath = child.snapshot.data['breadcrumbPath'];

        const breadcrumb: Breadcrumb = {
          name: child.snapshot.data['breadCrumbTitle'] || '',
          url: customPath || url, // Usar customPath si está definido, de lo contrario usar la URL construida.
        };

        // Solo agregar breadcrumbs con `title`.
        if (breadcrumb.name) {
          breadcrumbs.push(breadcrumb);
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
