export enum ParametersTypeEnum {
  DOCUMENT_TYPE = 'TIPOS DE DOCUMENTO',
  LEASING_TYPE = 'TIPO DE LEASING',
  TYPE_OF_PRODUCT = 'TIPO DE PRODUCTO',
  NEW_OR_USED = 'NUEVO O USADO',
  ACTIVE_OR_INACTIVO = 'ACTIVO O INACTIVO',
  ACTION_VARIABLE = 'ACCION VARIABLE',
  CITIES = 'CITIES',
  COUNTRIES = 'COUNTRIES',
  LIST_OF_DOCUMENTS = 'LISTA DE DOCUMENTOS',
  TYPE_OF_ASSETS = 'TIPO DE ACTIVOS',
  TYPE_OF_OPERATIONS = 'TIPO DE OPERACIONES',
  CHARACTERISTIC_OPERATION = 'CARACTERISTICAS DE LA OPERACION',
  ALLY_TYPE = 'TEST',
  PERIODICITY = 'PERIOCIDAD',
  READJUSTMENT_FORMULA = 'FORMULA DE REAJUSTE',
  NUMBER_MONTHS = 'NUMERO DE MESES',
  FILE_TYPE = 'TIPO DE ARCHIVO',
  DAYS_BASE_OPTIONS = 'DAYS_BASE_OPTIONS',
  // ENUM PARA SELECT-BOX
  PARAMETER_STATUS = 'ESTADO DEL PARÁMETRO',
  DAYS_BASE = 'BASE DE DIAS',
  FEE_COLLECTION = 'COBRO DEL CANON',
  YES_OR_NOT = 'SI O NO',
  DAYS_OF_MONTH = 'DIAS DEL MES',
  MODALITY = 'MODALIDAD',
}
