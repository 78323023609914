import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Numbers } from '@constants/numbers';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiHelperService {
  private headers: HttpHeaders = new HttpHeaders();
  private errorSubject = new Subject<any>();

  constructor(private http: HttpClient) {}

  public baseurl = environment.URL_API;

  errorHandler(errorObj: any) {
    return throwError(() => errorObj);
  }

  complete<TResponse>(responseJson: any) {
    return responseJson;
  }

  completeDummy<TResponse>(responseJson: any, endPoint: string) {
    return responseJson[endPoint];
  }

  public getJSON(): Observable<any> {
    return this.http.get('./assets/mydata.json');
  }

  // Método para mostrar alertas de error
  public showErrorAlert(error: any) {
    this.errorSubject.next(error);
  }

  getErrorSubject() {
    return this.errorSubject.asObservable();
  }

  // Método GET
  public apiGet<TResponse>(endPoint: string): Observable<TResponse> {
    return this.getJSON().pipe(
      map((responseJson) => this.completeDummy<TResponse>(responseJson, endPoint)),
      retry(Numbers._1),
      catchError((error) => {
        this.showErrorAlert(error);
        return this.errorHandler(error);
      })
    );
  }

  // Método GET
  public get(endpoint: string, errorMsg?: string, config?: {}): Observable<any> {
    return this.http.get(`${this.baseurl}/${endpoint}`, config).pipe(
      map(this.mapResponse),
      catchError((error) => {
        this.showErrorAlert(error);
        return this.catchError(error);
      })
    );
  }

  // Método DELETE
  public delete(endpoint: string, errorMsg?: string, config?: {}): Observable<any> {
    return this.http.delete(`${this.baseurl}/${endpoint}`, config).pipe(
      map(this.mapResponse),
      catchError((error) => {
        this.showErrorAlert(error);
        return this.catchError(error);
      })
    );
  }

  // Método POST
  public post<T>(endpoint: string, data?: {}, errorMsg?: string, config?: {}): Observable<T> {
    return this.http.post<T>(`${this.baseurl}/${endpoint}`, data, config).pipe(
      map(this.mapResponse),
      catchError((error) => {
        this.showErrorAlert(error);
        return this.catchError(error);
      })
    );
  }

  // Método PUT
  public put<T>(endpoint: string, data?: {}, errorMsg?: string, config?: {}): Observable<T> {
    return this.http.put<T>(`${this.baseurl}/${endpoint}`, data, config).pipe(
      map(this.mapResponse),
      catchError((error) => {
        this.showErrorAlert(error);
        return this.catchError(error);
      })
    );
  }

  mapResponse = (response: any) => response;
  catchError = (error: any) => throwError(() => error);

  // Método PATCH
  public patch<T>(endpoint: string, data?: any, errorMsg?: string, config?: {}): Observable<T> {
    return this.http.patch<T>(`${this.baseurl}/${endpoint}`, data, { headers: this.headers }).pipe(
      map(this.mapResponse),
      catchError((error) => {
        this.showErrorAlert(error);
        return this.catchError(error);
      })
    );
  }
}
